.__jazzbar {
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  height: 4px;
  background: #63e6be;
  opacity: 1;
  transition: all 0.4s ease-in;
}

.__jazzbar.hiding {
  height: 0px;
  opacity: 0;
  transition: all 0.2s ease-in;
}

.__jazzbar.zero {
  width: 0px !important;
  transition: none;
}